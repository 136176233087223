// import sayHello from './lib/common.js';
import './lib/common.js'; // Активация jQuery вне Webpack, создание глобальной переменной $ 
import {TimelineMax} from 'gsap'; // Анимация
// import Splitter from 'split-html-to-chars'; // для разделения букв в тексте
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import lazyload from 'lazyload'; // Подгрузка изображений
import owlCarousel from 'owl.carousel'; // Карусель
import Cookies from 'js-cookie'; // Js-cookie
// import './lib/detectTouch.js';

// Подставляем текст в прелоадер
// let arr = ['Хороший выбор...', 'We make a good sh*t...', 'Превертствуем тебя путник...'];
// let rand = Math.floor(Math.random() * arr.length);

// $('.loader .loader__text').text( arr[rand] );

// Разделяем каждую букву
// let els = document.querySelectorAll('.splitter');
// [].forEach.call(els, function(el) {
//   el.outerHTML = Splitter(el.outerHTML, '<span class="letter">$</span>');
// });

let tl = new TimelineMax();

let windowHeight = $(document).height();

tl
// Анимация подставляемого текста в прелоадере
// .staggerFromTo('.loader__text .letter', 0.3, {y:30, opacity: 0}, {y: 0, opacity: 1}, 0.03) 
  // .to('.loader', 0.5, {ease: Power2.easeOut, y: -windowHeight, height: 0, opacity: 0}, 2)
  .fromTo('.top-line__left', 0.3, {y:30,opacity: 0}, {y: 0, opacity: 1}, '-=1')
  .fromTo('.top-line__right', 0.3, {y:30,opacity: 0}, {y: 0, opacity: 1}, '-=0.7')
  .staggerFromTo('.header__content .stagger', 0.3, {y:-30,opacity: 0}, {y: 0, opacity: 1}, 0.03)
  .staggerFromTo('.top-line__menu li', 0.2, {y:-30,opacity: 0}, {y: 0, opacity: 1}, 0.05);

// Анимация бургера если есть 
// $('.menu_button').on('click', function(e) {
//   e.preventDefault;
//   $(this).toggleClass('mnu_btn__active');
//   $('.menu').toggleClass('mnu_btn__active');
//   return false;
// });

// Скролинг по лендингу при нажатии на ссылку
$('.scroll_to').on('click', function(event) {
  event.preventDefault();
  var id  = $(this).attr('href'),
    top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
});	



// Вставка в форму комментария от нажатой кнопки
// открывающей модальное окно
$('.button[href="#form"]').on('click', function() {
  let button = $(this).data('button');
  $('form .hidden input[name="button"]').remove();
  $('form .hidden').append('<input type="hidden" name="button" value="'+button+'">');
});

// Анимация для модального окна
function modalTl() {
  tl.staggerFromTo('.modal-wrap', 0.5, {x:-30,opacity: 0}, {x: 0, opacity: 1}, 0.1);
}
// Модальное окно
$('[data-fancybox]').fancybox({
  afterLoad : function() {
    modalTl(); // Функция анимации
  }
});

// $.fancybox.open({
//   src  : '#thanks',
//   type : 'inline',
//   opts : {
//     afterShow : function( instance, current ) {
//       console.info( 'done!' );
//     }
//   }
// });

// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// Подгрузка изображений
$('img.lazyload').lazyload();

// Карусели
let owlButtons = ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'];

$('.owl-works').owlCarousel({
  responsiveClass: true,
  margin: 15,
  nav: true,
  navText: owlButtons,
  dots: true,
  lazyLoad: true,
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  lazyLoadEager: 2,
  responsive:{
    0 : {items:1, lazyLoadEager:1},
    600 : {items:3},
    1000 : {items:5},
    1400 : {items:6}
  }
});

$('.owl-reviews').owlCarousel({
  responsiveClass: true,
  margin: 15,
  nav: true,
  navText: owlButtons,
  autoHeight: true,
  dots: true,
  lazyLoad: true,
  loop: true,
  mouseDrag: false,
  lazyLoadEager: 2,
  responsive:{
    0 : {items:1, lazyLoadEager:1},
    600 : {items:2},
    1200 : {items:3}
  }
});

// Отработка формы, проверка на пустые поля 

var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() {
  let th = $(this).find('.hidden');
  $.each(params, function(key, value) {
    th.append('<input name="'+key+'" value="'+value+'" hidden>');
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.btn-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);
      form.submit();

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send_request'});
        fbq('track','Lead');
        // yaCounter34842275.reachGoal('lead_form_order');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
        // form.submit();
      }
    });
  });
});



// SOCIAL MODAL
var social = $('#social_modal'), 
  socialDelay = social.data('delay') * 1e3,
  date = new Date(),
  minutes = 60;


if (social.length > 0) {
  date.setTime(date.getTime() + (minutes * 60 * 1000));
  var popupShown = Cookies.get('socialpop');
  if(popupShown !== 'shown') {
    setTimeout(function() {
      $.fancybox.open({
        src :'#social_modal',
        type : 'inline',
        afterClose: function afterCloseSocPop(instance, current, e) {
          Cookies.set('socialpop', 'shown', { expires: date });
        }
      });
    },socialDelay);
  }
}

var rangeSlider = function() {
  var slider = $('label[range]'),
    range = $('input[type=range]'),
    value = $('.range-value');
		
  slider.each(function() {

    value.each(function() {
      var value = $(this).next().attr('value');
      $(this).html(value);
    });

    range.on('input', function() {
      $(this).prev(value).html(this.value);
    });
  });
};

rangeSlider();

// let services = [
//   {'title' : 'instagram', 'fullName' : 'Продвижение Instagram' , 'price' : 2999},
//   {'title' : 'promoads', 'fullName' : 'PromoAds' , 'price' : 3999},
//   {'title' : 'target', 'fullName' : 'Настройка таргетинга' , 'price' : 4999}
// ];

// let psList = $('.payment-service-list');

// services.forEach(function(item, i, arr) {
//   let paymentService = item.title + ' <br>';

//   let pService = '<div class="payment-service">'
//   +'<div class="payment-service">'
//   +'<label radio>'
//   +'<input type="radio" name="service" value="' + item.title + '">'
//   +'<span>' + item.fullName + '</span>'
//   +'</label>'
//   +'<a href="#" class="link link-white ml-2" data-fancybox><i class="fa fa-question-circle-o" aria-hidden="true"></i></a>'
//   +'</div>';
			
//   psList.append(pService);
// });

// $('.payment-service-list input[type=radio]').change(function() {
//   let servicePrice = $(this).data('price');
//   $('.payment-service-sum span').html(servicePrice);
//   $('.payment-service-sum input[name=sum]').val(servicePrice);
// });

// $('.payment-service-option-change .button').on('click', function(e) {
//   e.preventDefault();
//   let block = $('.payment-service-option-block'),
//     id = $(this).attr('href');
//   block.addClass('hidden');
//   setTimeout(function() {
//     $(id).removeClass('hidden');
//   }, 300);
// });

$('.accordion .accordion_link').click(function(e) {
  var dropDown = $(this).closest('.accordion_wrap').find('.accordion_content');
  $(this).closest('.accordion').find('.accordion_content').not(dropDown).slideUp();
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
  } else {
    $(this).closest('.accordion').find('.accordion_link.active').removeClass('active');
    $(this).addClass('active');
  }
  dropDown.stop(false, true).slideToggle();
  e.preventDefault();
});

$('[data-payment]').on('click', function(e) {
  // console.log(e);
  let method = $(this).data('payment'),
    price = $(this).data('price'),
    service = $(this).data('service'),
    doc = $(this).data('doc');
  console.log(doc);
  $.fancybox.open( $('#' + method), {
    touch: false,
    afterLoad : function() {
      $('#' + method + ' .service_name').html(service);
      $('#' + method + ' .price').val(price);
      $('#' + method + ' .service').val(service);
      $('#' + method + ' .service_doc').attr('href',doc);
      modalTl(); // Функция анимации
    }
  });
  e.preventDefault();
});

$('#liqpay form').submit(function() {
  var th = $(this);
  $.ajax({
    type: 'POST',
    url: '/assets/static/_payment/liqpay/makeform.php',
    data: th.serialize()
  }).done(function(data) {
    $('#form_responce').html(data);
    $('#form_responce form').submit();
  });
  return false;
});

$('#payparts form').submit(function() {
  var th = $(this);
  $.ajax({
    type: 'POST',
    url: '/assets/static/_payment/payparts/makeform.php',
    data: th.serialize()
  }).done(function(data) {
    window.location.href = data;
  });
  return false;
});


$('#copy').click(function() {
  var temp = $('<input>'),
    text = $('.card span').text();
  $('body').append(temp);
  temp.val(text).select();
  document.execCommand('copy');
  temp.remove();

});

// var phone = $('form').find('input[name=phone]').val();
// $.fancybox.open({
//   src  : '#thanks',
//   type : 'inline',
//   afterLoad: function() {
//     $('.check-phone').empty().append(phone);
//     modalTl();
//   }
// });

var countdowntime = $('#countdown').data('time');


CountDownTimer(countdowntime, 'countdown');

function CountDownTimer(dt, id) {
  var end = new Date(dt);

  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var timer;

  function showRemaining() {
    var now = new Date();
    var distance = end - now;
    if (distance < 0) {

      clearInterval(timer);
      document.getElementById(id).innerHTML = 'Закончилось!';

      return;
    }
    var days = Math.floor(distance / _day);
    var hours = Math.floor((distance % _day) / _hour);
    var minutes = Math.floor((distance % _hour) / _minute);
    var seconds = Math.floor((distance % _minute) / _second);

    document.getElementById(id).innerHTML = days + ' д / ';
    document.getElementById(id).innerHTML += hours + ' ч / ';
    document.getElementById(id).innerHTML += minutes + ' м / ';
    document.getElementById(id).innerHTML += seconds + ' сек';
  }

  timer = setInterval(showRemaining, 1000);
}
